<template>
    <div>
        <b-card no-body>
            <div class="m-2">
                <div>

                    <b-row>
                        <b-col
                            cols="12"
                            md="6"
                            sm="12"
                        >
                            <h1>{{ i18nT(`Quotes`) }}</h1>
                            <div class="mb-1">
                                <span class="text-muted">{{ i18nT(`Showing an overview of the coversion rate of your quotes`) }}</span>
                            </div>
                        </b-col>

                        <b-col
                            cols="12"
                            md="6"
                            sm="12">
							<b-dropdown
								v-if="iCan( 'payments', 'write' )"
								class="float-right"
								right
								id="add-new-dropdown"
								variant="primary"
							>
								<template #button-content>
									<feather-icon icon="PlusCircleIcon" />
									{{ i18nT(`Add new`) }}
								</template>
								<b-dropdown-item>
									<b-link
										class="text-primary"
										:to="{ name: 'quote-create'}"
										variant="primary"
									>
										<font-awesome-icon class="mr-25" :icon="['fas', 'plus-circle']" />
										{{ i18nT(`Create quote`) }}
									</b-link>
								</b-dropdown-item>
								<b-dropdown-item
									v-b-modal.modal-export
									:disabled="totalDocuments == 0"
								>
									<div :class="{'text-primary': totalDocuments !== 0}">
										<font-awesome-icon class="mr-25 ml-25" :icon="['fas', 'file-export']" />
										{{ i18nT(`Export`) }}
									</div>
								</b-dropdown-item>
							</b-dropdown>
<!--                            <b-button-->
<!--                                variant="primary"-->
<!--                                :to="{ name: 'quote-create'}"-->
<!--                                class="float-right"-->
<!--                                v-if="iCan( 'payments', 'write' )"-->
<!--                            >-->
<!--                                <feather-icon icon="PlusCircleIcon"/>-->
<!--                                {{ i18nT(`Add new`) }}-->
<!--                            </b-button>-->
                        </b-col>
                    </b-row>
                    <!-- CHART AND REPORTING -->
                    <quote-report v-if="controlVisible" @status-update="getQuotesByStatus" ></quote-report>

                    <b-row>
                        <b-col cols="12" v-if="controlVisible">
                            <div class="mb-1">
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                dataMeta.from
                            }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                dataMeta.of
                            }} {{ i18nT(`entries`) }}</span>
                            </div>
                        </b-col>
                    </b-row>


                    <b-row>
                        <b-col
                            cols="12"
                            md="3"
                            v-if="displayExtentControls && controlVisible"
                        >
                            <b-form-group
                                :label="i18nT(`Vendors`)"
                                label-for="assignee"
                            >
                                <v-select
                                    id="assignee"
                                    v-model="vendor"
                                    :options="vendors"
                                    label="text"
									:clearable="false"
                                />
                            </b-form-group>
                        </b-col>

                    </b-row>
                </div>
            </div>

            <div>
                <div>
                    <div
                        class="row mx-2"
                        v-if="displayExtentControls"
                    >
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pl-0">
                            <v-select
                                v-model="perPage"
                                :options="perPageOptions"
                                :clearable="false"
                                class="per-page-selector d-inline-block"
                            />
                            <b-dropdown
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                text="Actions"
                                size="md"
                                class="d-inline ml-1"
                                variant="outline-secondary"
                                v-if="selectedItems.length > 0"
                            >
<!--                                <b-dropdown-item-->
<!--                                    v-if="iCan( 'payments', 'write' )"-->
<!--                                    @click="onBulkCopy(data)">-->
<!--                                    <feather-icon icon="CopyIcon"/>-->
<!--                                    <span class="align-middle ml-50">{{ i18nT(`Copy`) }}</span>-->
<!--                                </b-dropdown-item>-->
                                <b-dropdown-item
                                    v-if="iCan( 'payments', 'write' )"
                                    @click="onBulkDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                            <!-- <b-dropdown
                              size="md"
                              class="d-inline ml-1"
                              variant="outline-secondary"
                            >
                              <template v-slot:button-content>
                                <feather-icon icon="ExternalLinkIcon" />&nbsp;&nbsp;{{i18nT(`Export`)}}
                              </template>
                              <b-dropdown-item @click="onExportExcel()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{i18nT(`Excel`)}}</span>
                              </b-dropdown-item>
                              <b-dropdown-item @click="onExportCsv()">
                                <feather-icon icon="CopyIcon" />
                                <span class="align-middle ml-50">{{i18nT(`CSV`)}}</span>
                              </b-dropdown-item>
                            </b-dropdown> -->
                        </div>
                        <!-- search input -->
                        <div class="col-12 col-md-6 col-sm 6 mb-1 pr-0">
                            <div class="custom-search d-flex justify-content-end">
                                <b-form-group>
                                    <div
                                        role="group"
                                        class="input-group input-group-merge"
                                    >
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <feather-icon icon="SearchIcon"/>
                                            </div>
                                        </div>
                                        <b-form-input
                                            v-model.lazy="searchQuery"
                                            :placeholder="i18nT(`Search`)"
                                            type="text"
                                            class="d-inline-block"
                                        />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <b-row>

                  </b-row>
                    <b-table
                        ref="refDocumentListTable"
                        :items="fetchDocuments"
                        responsive
                        :fields="computedColumns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        :empty-html="emptyHtml"
                        :sort-desc.sync="isSortDirDesc"
                        class="position-relative"
                        @refreshed="onTableRefresh"
                        v-model="currentItems"
                    >

                        <template v-slot:head(checkbox)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <b-form-checkbox
                                class="d-inline-block pl-0"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                            </b-form-checkbox>
                        </template>

                        <template #head(action)="data">
                            <span>{{ data.label.toUpperCase() }}</span>
                            <div class="sk-column-control">
                                <b-dropdown
                                    no-caret
                                    variant="button"
                                    toggle-class="p-0"
                                    :right="true"
                                    class="pr-1 pl-1 d-flex sk-column-filter"
                                    v-if="betaFeature"
                                    v-b-tooltip = "i18nT(`Customize columns`)"
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="SettingsIcon"
                                            size="18"
                                            class="text-right"
                                        />
                                    </template>

                                    <div class="pl-1 pr-1" v-for="(field, index) in avalableFields" :key="'Fields - ' + index">
                                        <b-form-checkbox class="mb-1 mt-1 text-nowrap" v-model="field.visible">{{ i18nT(field.label) }}</b-form-checkbox>
                                    </div>
                                </b-dropdown>
                            </div>
                        </template>

                        <template #cell(checkbox)="data">
                            <b-form-checkbox
                                v-model="selectedItems"
                                :value="data.item.Id"
                            />
                        </template>

                        <template #cell(number)="data">
                            <b-link :to="{
                                      name: 'quote-preview',
                                      params: { id: data.item.Id },
                                    }">
                                {{ data.item.QuoteNumber}}
                            </b-link>
                        </template>


                        <template #cell(amount)="data">
                                {{ {amount: data.item.TotalAmount, currency: data.item.Currency} | currency }}
                        </template>

                        <template #cell(status)="data">
                            <span
                                :class="statusBadgeClasses(data.item)">
                              {{ getStatusTitle(data.item.Status) }}
                            </span>
                        </template>




                        <template #cell(label)="data">
                            <b-link :to="{
                                      name: 'quote-preview',
                                      params: { id: data.item.Id },
                                    }">
                                {{ data.item.Label}}
                            </b-link>
                        </template>


                        <template #cell(record_type)="data">
                            {{ data.item.RecordTypeObj ? data.item.RecordTypeObj.Label : '' }}
                        </template>


                        <template #cell(vendor)="data">
                            <span v-if="data.item.VendorObj">{{ data.item.VendorObj.Label }}</span>
                        </template>

                      <template #cell(created)="data">
                        {{ data.item.QuoteDate | date }}
                      </template>

                        <template #cell(ExpirationDate)="data">
                            {{ data.item.ExpirationDate | date }}
                        </template>


                        <template #cell(action)="data">
                            <b-dropdown
                                variant="link"
                                toggle-class="p-0"
                                no-caret
                                :right="$store.state.appConfig.isRTL"
                            >

                                <template #button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="align-middle text-body"
                                    />
                                </template>



                                <b-dropdown-item :to="{ name: 'quote-preview', params: { id: data.item.Id } }">
                                    <feather-icon icon="EyeIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Preview`) }}</span>
                                </b-dropdown-item>

                                <b-dropdown-item :to="{ name: 'quote-edit', params: { id: data.item.Id } }">
                                    <feather-icon icon="EditIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Edit`) }}</span>
                                </b-dropdown-item>
                                
                                <b-dropdown-item v-if="data.item.Status !== 'accepted'" @click="changeStatus(data.item.Id, 'accepted')">
                                    <feather-icon icon="ThumbsUpIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Mark as Approved`) }}</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="data.item.Status !== 'pending'" @click="changeStatus(data.item.Id, 'pending')">
                                    <feather-icon icon="ClockIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Mark as Pending`) }}</span>
                                </b-dropdown-item>

                                <b-dropdown-item v-if="data.item.Status !== 'rejected'" @click="changeStatus(data.item.Id, 'rejected')">
                                    <feather-icon icon="ThumbsDownIcon"/>
                                    <span class="align-middle ml-50">{{ i18nT(`Mark as Rejected`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onCopy(data)">
                                  <feather-icon icon="CopyIcon" />
                                  <span class="align-middle ml-50">{{i18nT(`Copy`)}}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onCreateInvoice(data)">
                                    <feather-icon icon="PlusIcon" class="text-primary"/>
                                    <span class="align-middle ml-50 text-primary"
                                    >{{ i18nT(`Create invoice`) }}</span>
                                </b-dropdown-item>
                                <b-dropdown-item @click="onDelete(data)">
                                    <feather-icon
                                        icon="TrashIcon"
                                        color="red"
                                    />
                                    <span
                                        class="align-middle ml-50"
                                        style="color: red"
                                    >{{ i18nT(`Delete`) }}</span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </template>
                    </b-table>

                </div>

                <div class="mx-1 mb-2">
                    <b-row>
                        <!-- <b-col> -->
                        <!-- </b-col> -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start"
                        >
                            <b-form-checkbox
                                class="d-inline-block mx-1"
                                @change="onSelectAll"
                                v-model="selectAllStatus"
                            >
                                {{ i18nT(`All`) }}
                            </b-form-checkbox>
                            <span class="text-muted">{{ i18nT(`Showing`) }} {{
                                    dataMeta.from
                                }} {{ i18nT(`to`) }} {{ dataMeta.to }} {{ i18nT(`of`) }} {{
                                    dataMeta.of
                                }} {{ i18nT(`entries`) }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col
                            cols="12"
                            sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end"
                        >
                            <b-pagination
                                v-model="currentPage"
                                :total-rows="totalDocuments"
                                :per-page="perPage"
                                first-number
                                last-number
                                class="mb-0 mt-1 mt-sm-0"
                                prev-class="prev-item"
                                next-class="next-item"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </div>
        </b-card>
		<modal-export module="quotes" :allFilters="allFilters"/>
    </div>
</template>

<script>
import {
    BLink,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BButton,
    BTable,

    BDropdown,
    BDropdownItem,
    BPagination,

    BFormCheckbox,
    BFormInput,
    VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useInvoiceList from './useQuoteList'
import {avatarText} from '@core/utils/filter'
import {dictToSelectArray} from '@core/utils/utils'
import router from '@/router'
import useAuth from '@/auth/useAuth'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import QuoteReport from "@/views/accounting/Quotes/QuoteReport.vue";
import ModalExport from "@/views/common/ModalExport/ModalExport.vue";


export default {
    components: {
		ModalExport,
        QuoteReport,
        BLink,
        BRow,
        BCol,
        BFormGroup,
        BCard,
        BButton,
        BTable,

        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
        BFormCheckbox,
        BFormInput
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip
    },
    props: {
        selectedFinanceType: {
            type: String
        }
    },
    data() {
        const displayExtentControls =
            useAuth.getCurrentSoftware() != 'ca' &&
            useAuth.getCurrentSoftware() != 'em'
        return {
            currency: 'EUR',
            report: {},
            reportRange: null,

            vendors: [],
            pagination: {},
            selectedItems: [],
            currentItems: [],
            selectAllStatus: false,
            displayExtentControls,
            avalableFields: [
                {label: this.i18nT(`Project`), key: 'label', visible: true},
                {label: this.i18nT(`Number`), key: 'number', visible: true},
                {label: this.i18nT(`Client`), key: 'vendor', visible: true},
                {label: this.i18nT(`Amount`), key: 'amount', visible: true},
                {label: this.i18nT(`Status`), key: 'status', visible: true},
                {label: this.i18nT(`Expires on`), key: 'ExpirationDate', visible: false},
                {label: this.i18nT(`Quote date`), key: 'created', visible: true},
            ],
            documentFilters: [
                {value: '', text: this.i18nT(`All`)},
                {value: 'created', text: this.i18nT(`Created by me`)},
                {value: 'shared', text: this.i18nT(`Shared by me`)} 
            ],
            controlVisible: true,
        }
    },
    mounted() {},

    created() {

        let storePreferences = useAuth.getColumnPreferences('QuotesTemplates');
          if(storePreferences) {
            this.avalableFields = storePreferences
        }

        this.$http.get(`vendors/vendorsList?clients=1`).then(({data}) => {
            this.vendors = dictToSelectArray(data.data)
            this.vendors.unshift({
                value: -1,
                text: this.i18nT(`All`)
            })
        })

        this.$http.get(`quotes/summary`).then(({ data }) => {
            this.report = data.data
            this.currency = data.data.currency
        })

        this.fetchDocuments(null, this.determineVisibility);

    },
    methods: {
        determineVisibility(invoiceArray) {
            if (invoiceArray.length === 0) {
              this.controlVisible = false;
            }
        },
        onCreateInvoice(data){
            let formData = new FormData();
            formData.append('quote_id', data.item.Id);

            this.$http.post(`quotes/createInvoice`, formData).then((response) => {
                console.log('response', response);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.i18nT(`Success`),
                        icon: 'CheckIcon',
                        variant: 'success',
                        message: this.i18nT(`Invoice created successfully`)
                    },
                });
                this.$router.push({name: 'edit-invoice', params: {id: response.data.data.Id}});
            });
        },
        changeStatus(itemId, status) {

            let formData = new FormData();
            formData.append('Status', status);
            formData.append('quote_id', itemId);

            this.$http.post(`quotes/quoteStatus`, formData).then(() => {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: this.i18nT(`Success`),
                        icon: 'CheckIcon',
                        variant: 'success',
                        message: status == 'accepted' ? this.i18nT(`Quote accepted successfully`) : this.i18nT(`Quote rejected successfully`)
                    },

                });

                this.refetchData();

            })
        },


        statusBadgeClasses(item) {
            let base_classes = 'badge badge-pill ';
            switch (item.Status) {
                case 'pending':
                    return base_classes + 'badge-light-warning';
                case 'accepted':
                    return base_classes + 'badge-light-success';
                case 'rejected':
                    return base_classes + 'badge-light-danger';
                default:
                    return base_classes + 'badge-light-secondary';
            }
        },

        getStatusTitle(status) {
            return this.i18nT(status.charAt(0).toUpperCase() + status.slice(1));
        },
        getQuotesByStatus(status) {
            this.status = status;
        },
        getAssigneeName(assignee) {
            const assigneeList = Object.values(assignee)
            return assigneeList.map(ass => ass.Label).join(', ')
        },
        getAvatarName(name) {
            if (name) {
                return name
                    .split(' ')
                    .map(namePart => {
                        return namePart[0]
                    })
                    .join('')
                    .toUpperCase()
            } else {
                return ''
            }
        },
        onTableRefresh() {
            this.selectedItems = []
        },
        onCopy(element) {
            this.$http
                .post(`quotes/copy?quote_id=${element.item.Id}`)
                .then(() => {
                    this.refetchData()
                })
        },
        onDelete(task) {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes delete it`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http.delete(`quotes?id=${task.item.Id}`).then(() => {
                        this.$swal({
                            icon: 'success',
                            title: this.i18nT(`Deleted!`),
                            text: this.i18nT(`The document has been deleted.`),
                            customClass: {
                                confirmButton: 'btn btn-success'
                            }
                        })
                        this.refetchData()
                    })
                }
            })
        },
        onBulkCopy() {
            this.$http
                .post(
                    `quotes/copy?quote_id=${this.selectedItems.join(',')}`
                )
                .then(() => {
                    this.selectedItems = []
                    this.selectAllStatus = false
                    this.refetchData()
                })
        },
        onBulkDelete() {
            this.$swal({
                title: this.i18nT(`Are you sure?`),
                text: this.i18nT(`You won't be able to revert this!`),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: this.i18nT(`Yes, delete it!`),
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$http
                        .delete(`quotes?id=${this.selectedItems.join(',')}`)
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: this.i18nT(`Deleted!`),
                                text:  this.i18nT(`Selected quote(s) were deleted!`),
                                customClass: {
                                    confirmButton: 'btn btn-success'
                                }
                            })
                            this.selectedItems = []
                            this.selectAllStatus = false
                            this.refetchData()
                        })
                }
            })
        },
        onSelectAll(val) {
            if (val) {
                this.selectedItems = this.currentItems.map(item => item.Id)
            } else {
                this.selectedItems = []
            }
        },
        onExportExcel() {
        },
        onExportCsv() {
        }
    },
    setup() {

        const {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refDocumentListTable,
            statusFilter,
            invoiceType,
            recordType,
            assignedTo,
            vendor,
            status,
            //paymentType,
            documents,
            documentFilter,
            refetchData
        } = useInvoiceList(router.currentRoute.meta.template === 1)

        return {
            fetchDocuments,
            tableColumns,
            perPage,
            currentPage,
            totalDocuments,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            status,
            isSortDirDesc,
            refDocumentListTable,
            statusFilter,
            invoiceType,
            recordType,
            assignedTo,
            vendor,
            avatarText,
            documents,
            documentFilter,
            refetchData
        }
    },
    computed: {
        computedColumns() {
            let col = [...this.tableColumns]
            for(let i = 0; i < this.avalableFields.length; i++) {
              if(!this.avalableFields[i].visible) {
                col = col.filter(c => c.key !== this.avalableFields[i].key)
              }
            }
            useAuth.setColumnPreferences('QuotesTemplates', this.avalableFields);
            return col
        },
        emptyHtml() {
          return "<img src='" + require('@/assets/images/pages/error.svg') + "' alt='Error page' class='img-fluid' width='300'><br/><br/><h4>"
              + this.i18nT(`Here, you can create and manage your quotes. No quotes have been created yet.`) + "</h4>"
              +"<button class='btn btn-outline-secondary mt-2'  onclick='window.location.href=\"/ac/quotes/create\"'>" + this.i18nT(`Create quote`) + "</button>"
        },
		allFilters(){
			const params = {
				export: 1,
				sort_order: this.isSortDirDesc ? 'DESC' : 'ASC',
				search: this.searchQuery,
				sort_by: this.sortBy,
				template: 1,
			}

			if (this.vendor.value != -1) {
				params['filters[vendor]'] = this.vendor.value;
			}

			return params;

		},


    },
    watch: {
        fetchDocuments(newVal) {
            console.log("Local fetch documents are: ", newVal);
        },
        selectedFinanceType(newVal) {
            const invoiceType = this.invoiceTypes.find(e => e.value === newVal)
            if (invoiceType) this.invoiceType = invoiceType
        }
    }

}
</script>

<style lang="scss">
.task-container {
    .task-item {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .line-item {
        margin-top: 4px;
    }
}
</style>
